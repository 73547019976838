import ControlMapCommandData from "../../../types/mainWs/outMainWsData/ControlMapCommandData";
import {
	createOutMainWsData,
	OutMainWsDataTypes,
} from "../../../types/mainWs/outMainWsData/OutMainWsData";
import useSendOutMainWsData from "./useSendOutMainWsData";

const useSendControlMapCommandData = () => {
	const sendOutMainWsData = useSendOutMainWsData();
	return (command: ControlMapCommandData) => {
		sendOutMainWsData(createOutMainWsData(OutMainWsDataTypes.MAP_COMMAND, command));
	};
};

export default useSendControlMapCommandData;
