import React from "react";
import "../styles/components/header.scss";
import DisplayStatus from "./DisplayStatus";

const Header = () => {
	return (
		<div className="header">
			Community Dashboard
			<div className="flex gap-3">
				<DisplayStatus />
				<img src="/assets/svg/UWGC_Logo.svg" alt="uwgc_logo" />
			</div>
		</div>
	);
};

export default Header;
