import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import Card from "../../components/Card";
import { useGetStoryCallZipCodeServiceCountsArrLazyQuery } from "../../generated/graphql";
import { selectedStoryAtom, selectedZipCodeAtom } from "./public.recoil";

const Calls: React.FC = () => {
	const selectedStory = useRecoilValue(selectedStoryAtom)!;
	const selectedZipCode = useRecoilValue(selectedZipCodeAtom)!;

	const [getStoryCallZipCodeServiceCountsArr, getStoryCallZipCodeServiceCountsArrRes] =
		useGetStoryCallZipCodeServiceCountsArrLazyQuery();

	useEffect(() => {
		void getStoryCallZipCodeServiceCountsArr({
			variables: {
				input: {
					top: 5,
					filter: {
						storyId: selectedStory.id,
						zipCodes: [selectedZipCode.zipCode],
					},
				},
			},
		});
	}, [selectedZipCode]);

	const zipCodeServiceCountsArr =
		getStoryCallZipCodeServiceCountsArrRes.data?.getStoryCallZipCodeServiceCountsArr.rows ?? [];

	return (
		<Card className="food-insecurity-calls text-center text-lg">
			{selectedStory.id === "food_insecurity" && <>Top food insecurity calls in zip code</>}
			{selectedStory.id === "mental_health" && <>Top mental health calls in zip code</>}
			{selectedStory.id === "housing" && <>Top housing calls in zip code </>}{" "}
			{selectedZipCode.zipCode}
			{zipCodeServiceCountsArr.length !== 0 &&
				zipCodeServiceCountsArr.map((zipCodeServiceCounts, i) => {
					return zipCodeServiceCounts.serviceCounts.map((serviceCount, j) => {
						return (
							<div
								className="flex text-2xl font-semibold text-uwgc-charcoal text-left my-3 leading-tight"
								key={i + "-" + j}
							>
								<div className="w-[30%] pr-3 shrink-0 text-right text-2xl font-extrabold text-uwgc-orange ">
									{serviceCount.count.toLocaleString("en-US")}
								</div>{" "}
								{serviceCount.service}
							</div>
						);
					});
				})}
		</Card>
	);
};

export default Calls;
