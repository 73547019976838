import { atom } from "recoil";
import WsConnectionStatus from "../../types/WsConnectionStatus";
import InPublicControlWsData from "../../types/publicControlWs/inPublicControlWsData/InPublicControlWsData";
import WebSocketManager from "../../classes/WebSocketManager";
import OutPublicControlWsData from "../../types/publicControlWs/outPublicControlWsData/OutPublicControlWsData";

export const publicControlWsAtom = atom<
	WebSocketManager<InPublicControlWsData, OutPublicControlWsData> | undefined
>({
	key: "publicControlWs",
	default: undefined,
	dangerouslyAllowMutability: true,
});

export const publicControlWsConnectionStatusAtom = atom<WsConnectionStatus>({
	key: "publicControlWsConnectionStatus",
	default: WsConnectionStatus.NOT_CONNECTED,
});
