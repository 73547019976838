import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: Date;
  NullableBoolean: boolean | null;
  NullableDate: Date | null;
  NullableFloat: number | null;
  NullableInt: number | null;
  NullableString: string | null;
};

export type GetStoryCallZipCodeAgeGroupCountsArrFilter = {
  storyId: Scalars['String'];
  zipCodes: Array<Scalars['String']>;
};

export type GetStoryCallZipCodeAgeGroupCountsArrInput = {
  ageGroups: Array<StoryCallAgeGroup>;
  filter: GetStoryCallZipCodeAgeGroupCountsArrFilter;
};

export type GetStoryCallZipCodeAgeGroupCountsArrOutput = {
  __typename?: 'GetStoryCallZipCodeAgeGroupCountsArrOutput';
  count: Scalars['Int'];
  rows: Array<StoryCallZipCodeAgeGroupCounts>;
};

export type GetStoryCallZipCodeGenderCountsFilter = {
  counties?: InputMaybe<Array<Scalars['String']>>;
  storyId: Scalars['String'];
  zipCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type GetStoryCallZipCodeGenderCountsInput = {
  filter: GetStoryCallZipCodeGenderCountsFilter;
};

export type GetStoryCallZipCodeGenderCountsOutput = {
  __typename?: 'GetStoryCallZipCodeGenderCountsOutput';
  count: Scalars['Int'];
  rows: Array<StoryCallZipCodeGenderCount>;
};

export type GetStoryCallZipCodeServiceCountsArrFilter = {
  storyId: Scalars['String'];
  zipCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type GetStoryCallZipCodeServiceCountsArrInput = {
  filter: GetStoryCallZipCodeServiceCountsArrFilter;
  top: Scalars['Int'];
};

export type GetStoryCallZipCodeServiceCountsArrOutput = {
  __typename?: 'GetStoryCallZipCodeServiceCountsArrOutput';
  count: Scalars['Int'];
  rows: Array<StoryCallZipCodeServiceCounts>;
};

export type GetStoryCallZipCodeServicesCountOutput = {
  __typename?: 'GetStoryCallZipCodeServicesCountOutput';
  count: Scalars['Int'];
  rows: Array<StoryCallZipCodeServicesCount>;
};

export type GetStoryCallZipCodeServicesCountsFilter = {
  counties?: InputMaybe<Array<Scalars['String']>>;
  storyId: Scalars['String'];
  zipCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type GetStoryCallZipCodeServicesCountsInput = {
  filter: GetStoryCallZipCodeServicesCountsFilter;
};

export type GetStoryCallZipCodesFilter = {
  counties?: InputMaybe<Array<Scalars['String']>>;
  likeZipCode?: InputMaybe<Scalars['String']>;
  storyId: Scalars['String'];
};

export type GetStoryCallZipCodesInput = {
  filter: GetStoryCallZipCodesFilter;
};

export type GetStoryCallZipCodesOutput = {
  __typename?: 'GetStoryCallZipCodesOutput';
  count: Scalars['Int'];
  rows: Array<StoryCallZipCode>;
};

export type GetStoryCallsCountFilter = {
  gteDateTransaction?: InputMaybe<Scalars['Date']>;
  lteDateTransaction?: InputMaybe<Scalars['Date']>;
  storyId: Scalars['String'];
  zipCode?: InputMaybe<Scalars['String']>;
};

export type GetStoryCallsCountInput = {
  filter: GetStoryCallsCountFilter;
};

export type GetZipCodesFilter = {
  likeZipCode?: InputMaybe<Scalars['String']>;
  zipCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type GetZipCodesInput = {
  filter?: InputMaybe<GetZipCodesFilter>;
  orderBys?: InputMaybe<Array<OrderBy>>;
  pagination?: InputMaybe<Pagination>;
};

export type GetZipCodesOutput = {
  __typename?: 'GetZipCodesOutput';
  count: Scalars['Int'];
  rows: Array<ZipCode>;
};

export type LoginInput = {
  isClear: Scalars['Boolean'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  token: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  login: LoginOutput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};

export type OrderBy = {
  by: Scalars['String'];
  isDesc?: InputMaybe<Scalars['Boolean']>;
};

export type Pagination = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type Permission = {
  __typename?: 'Permission';
  description?: Maybe<Scalars['NullableString']>;
  name: PermissionNames;
};

export enum PermissionNames {
  Admin = 'ADMIN',
  DisplayController = 'DISPLAY_CONTROLLER'
}

export type Query = {
  __typename?: 'Query';
  getCurrentUser: User;
  getStoriesJSON: Scalars['String'];
  getStoryCallZipCodeAgeGroupCountsArr: GetStoryCallZipCodeAgeGroupCountsArrOutput;
  getStoryCallZipCodeServiceCountsArr: GetStoryCallZipCodeServiceCountsArrOutput;
  getStoryCallZipCodes: GetStoryCallZipCodesOutput;
  getStoryCallsCount: Scalars['Int'];
  getZipCodes: GetZipCodesOutput;
};


export type QueryGetStoryCallZipCodeAgeGroupCountsArrArgs = {
  input: GetStoryCallZipCodeAgeGroupCountsArrInput;
};


export type QueryGetStoryCallZipCodeServiceCountsArrArgs = {
  input: GetStoryCallZipCodeServiceCountsArrInput;
};


export type QueryGetStoryCallZipCodesArgs = {
  input: GetStoryCallZipCodesInput;
};


export type QueryGetStoryCallsCountArgs = {
  input: GetStoryCallsCountInput;
};


export type QueryGetZipCodesArgs = {
  input?: InputMaybe<GetZipCodesInput>;
};

export type Role = {
  __typename?: 'Role';
  description?: Maybe<Scalars['NullableString']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  rolePermissions: Array<RolePermission>;
};

export type RolePermission = {
  __typename?: 'RolePermission';
  id: Scalars['Int'];
  permissionName: PermissionNames;
  permissionNamePermission: Permission;
  role: Role;
  roleId: Scalars['Int'];
};

export type StoryCallAgeGroup = {
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export type StoryCallAgeGroupCount = {
  __typename?: 'StoryCallAgeGroupCount';
  ageGroup: Scalars['String'];
  count: Scalars['Int'];
};

export type StoryCallZipCode = {
  __typename?: 'StoryCallZipCode';
  zipCode: Scalars['String'];
};

export type StoryCallZipCodeAgeGroupCounts = {
  __typename?: 'StoryCallZipCodeAgeGroupCounts';
  ageGroupCounts: Array<StoryCallAgeGroupCount>;
  zipCode: Scalars['String'];
};

export type StoryCallZipCodeGenderCount = {
  __typename?: 'StoryCallZipCodeGenderCount';
  count: Scalars['Int'];
  gender: Scalars['String'];
  zipCode: Scalars['String'];
};

export type StoryCallZipCodeServiceCount = {
  __typename?: 'StoryCallZipCodeServiceCount';
  count: Scalars['Int'];
  service: Scalars['String'];
};

export type StoryCallZipCodeServiceCounts = {
  __typename?: 'StoryCallZipCodeServiceCounts';
  serviceCounts: Array<StoryCallZipCodeServiceCount>;
  zipCode: Scalars['String'];
};

export type StoryCallZipCodeServicesCount = {
  __typename?: 'StoryCallZipCodeServicesCount';
  count: Scalars['Int'];
  zipCode: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  firstName?: Maybe<Scalars['NullableString']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['NullableString']>;
  userRoles: Array<UserRole>;
  username: Scalars['String'];
};

export type UserRole = {
  __typename?: 'UserRole';
  id: Scalars['Int'];
  role: Role;
  roleId: Scalars['Int'];
  user: User;
  userId: Scalars['Int'];
};

export type ZipCode = {
  __typename?: 'ZipCode';
  id: Scalars['Int'];
  latLng: Array<Scalars['Float']>;
  zipCode: Scalars['String'];
};

export type RoleFragment = { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, rolePermissions: Array<{ __typename?: 'RolePermission', id: number, roleId: number, permissionName: PermissionNames }> };

export type RolePermissionFragment = { __typename?: 'RolePermission', id: number, roleId: number, permissionName: PermissionNames };

export type UserFragment = { __typename?: 'User', id: number, username: string, firstName?: string | null | undefined, lastName?: string | null | undefined, userRoles: Array<{ __typename?: 'UserRole', id: number, userId: number, roleId: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, rolePermissions: Array<{ __typename?: 'RolePermission', id: number, roleId: number, permissionName: PermissionNames }> } }> };

export type UserRoleRowFragment = { __typename?: 'UserRole', id: number, userId: number, roleId: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, rolePermissions: Array<{ __typename?: 'RolePermission', id: number, roleId: number, permissionName: PermissionNames }> } };

export type StoryCallZipCodeFragment = { __typename?: 'StoryCallZipCode', zipCode: string };

export type StoryCallZipCodeServiceCountsFragment = { __typename?: 'StoryCallZipCodeServiceCounts', zipCode: string, serviceCounts: Array<{ __typename?: 'StoryCallZipCodeServiceCount', service: string, count: number }> };

export type StoryCallZipCodeAgeGroupCountsFragment = { __typename?: 'StoryCallZipCodeAgeGroupCounts', zipCode: string, ageGroupCounts: Array<{ __typename?: 'StoryCallAgeGroupCount', ageGroup: string, count: number }> };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginOutput', token: string } };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', getCurrentUser: { __typename?: 'User', id: number, username: string, firstName?: string | null | undefined, lastName?: string | null | undefined, userRoles: Array<{ __typename?: 'UserRole', id: number, userId: number, roleId: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, rolePermissions: Array<{ __typename?: 'RolePermission', id: number, roleId: number, permissionName: PermissionNames }> } }> } };

export type GetStoriesJsonQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStoriesJsonQuery = { __typename?: 'Query', getStoriesJSON: string };

export type GetStoryCallsCountQueryVariables = Exact<{
  input: GetStoryCallsCountInput;
}>;


export type GetStoryCallsCountQuery = { __typename?: 'Query', getStoryCallsCount: number };

export type GetStoryCallZipCodesQueryVariables = Exact<{
  input: GetStoryCallZipCodesInput;
}>;


export type GetStoryCallZipCodesQuery = { __typename?: 'Query', getStoryCallZipCodes: { __typename?: 'GetStoryCallZipCodesOutput', count: number, rows: Array<{ __typename?: 'StoryCallZipCode', zipCode: string }> } };

export type GetStoryCallZipCodeServiceCountsArrQueryVariables = Exact<{
  input: GetStoryCallZipCodeServiceCountsArrInput;
}>;


export type GetStoryCallZipCodeServiceCountsArrQuery = { __typename?: 'Query', getStoryCallZipCodeServiceCountsArr: { __typename?: 'GetStoryCallZipCodeServiceCountsArrOutput', count: number, rows: Array<{ __typename?: 'StoryCallZipCodeServiceCounts', zipCode: string, serviceCounts: Array<{ __typename?: 'StoryCallZipCodeServiceCount', service: string, count: number }> }> } };

export type GetStoryCallZipCodeAgeGroupCountsArrQueryVariables = Exact<{
  input: GetStoryCallZipCodeAgeGroupCountsArrInput;
}>;


export type GetStoryCallZipCodeAgeGroupCountsArrQuery = { __typename?: 'Query', getStoryCallZipCodeAgeGroupCountsArr: { __typename?: 'GetStoryCallZipCodeAgeGroupCountsArrOutput', count: number, rows: Array<{ __typename?: 'StoryCallZipCodeAgeGroupCounts', zipCode: string, ageGroupCounts: Array<{ __typename?: 'StoryCallAgeGroupCount', ageGroup: string, count: number }> }> } };

export const RolePermissionFragmentDoc = gql`
    fragment RolePermission on RolePermission {
  id
  roleId
  permissionName
}
    `;
export const RoleFragmentDoc = gql`
    fragment Role on Role {
  id
  name
  description
  rolePermissions {
    ...RolePermission
  }
}
    ${RolePermissionFragmentDoc}`;
export const UserRoleRowFragmentDoc = gql`
    fragment UserRoleRow on UserRole {
  id
  userId
  roleId
  role {
    ...Role
  }
}
    ${RoleFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  username
  firstName
  lastName
  userRoles {
    ...UserRoleRow
  }
}
    ${UserRoleRowFragmentDoc}`;
export const StoryCallZipCodeFragmentDoc = gql`
    fragment StoryCallZipCode on StoryCallZipCode {
  zipCode
}
    `;
export const StoryCallZipCodeServiceCountsFragmentDoc = gql`
    fragment StoryCallZipCodeServiceCounts on StoryCallZipCodeServiceCounts {
  zipCode
  serviceCounts {
    service
    count
  }
}
    `;
export const StoryCallZipCodeAgeGroupCountsFragmentDoc = gql`
    fragment StoryCallZipCodeAgeGroupCounts on StoryCallZipCodeAgeGroupCounts {
  zipCode
  ageGroupCounts {
    ageGroup
    count
  }
}
    `;
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  getCurrentUser {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetStoriesJsonDocument = gql`
    query getStoriesJSON {
  getStoriesJSON
}
    `;

/**
 * __useGetStoriesJsonQuery__
 *
 * To run a query within a React component, call `useGetStoriesJsonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoriesJsonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoriesJsonQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStoriesJsonQuery(baseOptions?: Apollo.QueryHookOptions<GetStoriesJsonQuery, GetStoriesJsonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoriesJsonQuery, GetStoriesJsonQueryVariables>(GetStoriesJsonDocument, options);
      }
export function useGetStoriesJsonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoriesJsonQuery, GetStoriesJsonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoriesJsonQuery, GetStoriesJsonQueryVariables>(GetStoriesJsonDocument, options);
        }
export type GetStoriesJsonQueryHookResult = ReturnType<typeof useGetStoriesJsonQuery>;
export type GetStoriesJsonLazyQueryHookResult = ReturnType<typeof useGetStoriesJsonLazyQuery>;
export type GetStoriesJsonQueryResult = Apollo.QueryResult<GetStoriesJsonQuery, GetStoriesJsonQueryVariables>;
export const GetStoryCallsCountDocument = gql`
    query getStoryCallsCount($input: GetStoryCallsCountInput!) {
  getStoryCallsCount(input: $input)
}
    `;

/**
 * __useGetStoryCallsCountQuery__
 *
 * To run a query within a React component, call `useGetStoryCallsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoryCallsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoryCallsCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStoryCallsCountQuery(baseOptions: Apollo.QueryHookOptions<GetStoryCallsCountQuery, GetStoryCallsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoryCallsCountQuery, GetStoryCallsCountQueryVariables>(GetStoryCallsCountDocument, options);
      }
export function useGetStoryCallsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoryCallsCountQuery, GetStoryCallsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoryCallsCountQuery, GetStoryCallsCountQueryVariables>(GetStoryCallsCountDocument, options);
        }
export type GetStoryCallsCountQueryHookResult = ReturnType<typeof useGetStoryCallsCountQuery>;
export type GetStoryCallsCountLazyQueryHookResult = ReturnType<typeof useGetStoryCallsCountLazyQuery>;
export type GetStoryCallsCountQueryResult = Apollo.QueryResult<GetStoryCallsCountQuery, GetStoryCallsCountQueryVariables>;
export const GetStoryCallZipCodesDocument = gql`
    query getStoryCallZipCodes($input: GetStoryCallZipCodesInput!) {
  getStoryCallZipCodes(input: $input) {
    count
    rows {
      ...StoryCallZipCode
    }
  }
}
    ${StoryCallZipCodeFragmentDoc}`;

/**
 * __useGetStoryCallZipCodesQuery__
 *
 * To run a query within a React component, call `useGetStoryCallZipCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoryCallZipCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoryCallZipCodesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStoryCallZipCodesQuery(baseOptions: Apollo.QueryHookOptions<GetStoryCallZipCodesQuery, GetStoryCallZipCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoryCallZipCodesQuery, GetStoryCallZipCodesQueryVariables>(GetStoryCallZipCodesDocument, options);
      }
export function useGetStoryCallZipCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoryCallZipCodesQuery, GetStoryCallZipCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoryCallZipCodesQuery, GetStoryCallZipCodesQueryVariables>(GetStoryCallZipCodesDocument, options);
        }
export type GetStoryCallZipCodesQueryHookResult = ReturnType<typeof useGetStoryCallZipCodesQuery>;
export type GetStoryCallZipCodesLazyQueryHookResult = ReturnType<typeof useGetStoryCallZipCodesLazyQuery>;
export type GetStoryCallZipCodesQueryResult = Apollo.QueryResult<GetStoryCallZipCodesQuery, GetStoryCallZipCodesQueryVariables>;
export const GetStoryCallZipCodeServiceCountsArrDocument = gql`
    query getStoryCallZipCodeServiceCountsArr($input: GetStoryCallZipCodeServiceCountsArrInput!) {
  getStoryCallZipCodeServiceCountsArr(input: $input) {
    count
    rows {
      ...StoryCallZipCodeServiceCounts
    }
  }
}
    ${StoryCallZipCodeServiceCountsFragmentDoc}`;

/**
 * __useGetStoryCallZipCodeServiceCountsArrQuery__
 *
 * To run a query within a React component, call `useGetStoryCallZipCodeServiceCountsArrQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoryCallZipCodeServiceCountsArrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoryCallZipCodeServiceCountsArrQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStoryCallZipCodeServiceCountsArrQuery(baseOptions: Apollo.QueryHookOptions<GetStoryCallZipCodeServiceCountsArrQuery, GetStoryCallZipCodeServiceCountsArrQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoryCallZipCodeServiceCountsArrQuery, GetStoryCallZipCodeServiceCountsArrQueryVariables>(GetStoryCallZipCodeServiceCountsArrDocument, options);
      }
export function useGetStoryCallZipCodeServiceCountsArrLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoryCallZipCodeServiceCountsArrQuery, GetStoryCallZipCodeServiceCountsArrQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoryCallZipCodeServiceCountsArrQuery, GetStoryCallZipCodeServiceCountsArrQueryVariables>(GetStoryCallZipCodeServiceCountsArrDocument, options);
        }
export type GetStoryCallZipCodeServiceCountsArrQueryHookResult = ReturnType<typeof useGetStoryCallZipCodeServiceCountsArrQuery>;
export type GetStoryCallZipCodeServiceCountsArrLazyQueryHookResult = ReturnType<typeof useGetStoryCallZipCodeServiceCountsArrLazyQuery>;
export type GetStoryCallZipCodeServiceCountsArrQueryResult = Apollo.QueryResult<GetStoryCallZipCodeServiceCountsArrQuery, GetStoryCallZipCodeServiceCountsArrQueryVariables>;
export const GetStoryCallZipCodeAgeGroupCountsArrDocument = gql`
    query getStoryCallZipCodeAgeGroupCountsArr($input: GetStoryCallZipCodeAgeGroupCountsArrInput!) {
  getStoryCallZipCodeAgeGroupCountsArr(input: $input) {
    count
    rows {
      ...StoryCallZipCodeAgeGroupCounts
    }
  }
}
    ${StoryCallZipCodeAgeGroupCountsFragmentDoc}`;

/**
 * __useGetStoryCallZipCodeAgeGroupCountsArrQuery__
 *
 * To run a query within a React component, call `useGetStoryCallZipCodeAgeGroupCountsArrQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoryCallZipCodeAgeGroupCountsArrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoryCallZipCodeAgeGroupCountsArrQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStoryCallZipCodeAgeGroupCountsArrQuery(baseOptions: Apollo.QueryHookOptions<GetStoryCallZipCodeAgeGroupCountsArrQuery, GetStoryCallZipCodeAgeGroupCountsArrQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoryCallZipCodeAgeGroupCountsArrQuery, GetStoryCallZipCodeAgeGroupCountsArrQueryVariables>(GetStoryCallZipCodeAgeGroupCountsArrDocument, options);
      }
export function useGetStoryCallZipCodeAgeGroupCountsArrLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoryCallZipCodeAgeGroupCountsArrQuery, GetStoryCallZipCodeAgeGroupCountsArrQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoryCallZipCodeAgeGroupCountsArrQuery, GetStoryCallZipCodeAgeGroupCountsArrQueryVariables>(GetStoryCallZipCodeAgeGroupCountsArrDocument, options);
        }
export type GetStoryCallZipCodeAgeGroupCountsArrQueryHookResult = ReturnType<typeof useGetStoryCallZipCodeAgeGroupCountsArrQuery>;
export type GetStoryCallZipCodeAgeGroupCountsArrLazyQueryHookResult = ReturnType<typeof useGetStoryCallZipCodeAgeGroupCountsArrLazyQuery>;
export type GetStoryCallZipCodeAgeGroupCountsArrQueryResult = Apollo.QueryResult<GetStoryCallZipCodeAgeGroupCountsArrQuery, GetStoryCallZipCodeAgeGroupCountsArrQueryVariables>;