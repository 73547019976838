import { ApolloProvider } from "@apollo/client";
import React from "react";
import { RecoilRoot } from "recoil";
import { apolloClient } from "./apollo.client";
import Main from "./views/Main";
import "./styles/generic.scss";
import { Version } from "./version";

const App: React.FC = () => {
	return (
		<div>
			<RecoilRoot>
				<ApolloProvider client={apolloClient}>
					<Main />
				</ApolloProvider>
			</RecoilRoot>
			<div style={{ display: "none" }}>v{Version.GIT_REV_COUNT()}</div>
		</div>
	);
};

export default App;
