import { useRecoilValue } from "recoil";
import MobileHeader from "../../components/MobileHeader";
import AgeGroupsChart from "./AgeGroupsChart";
import Donate from "./Donate";
import EnterZipCode from "./EnterZipCode";
import Calls from "./Calls";
import CallsCount from "./CallsCount";
import HoursPerMeal from "./HoursPerMeal";
import NeedsMet from "./NeedsMet";
import { selectedStoryAtom, selectedZipCodeAtom } from "./public.recoil";
import { isViewingAtom } from "./publicControl.recoil";
import SelectIssue from "./SelectIssue";
import ViewOnMonitors from "./viewOnMonitors/ViewOnMonitors";

const Public: React.FC = () => {
	const selectedStory = useRecoilValue(selectedStoryAtom);
	const selectedZipCode = useRecoilValue(selectedZipCodeAtom);
	const isViewing = useRecoilValue(isViewingAtom);

	return (
		<div className="public bg-uwgc-lightGray">
			<MobileHeader />

			{!isViewing && <SelectIssue />}
			{selectedStory && (
				<>
					{!isViewing && <EnterZipCode />}
					{selectedZipCode &&
						(selectedZipCode.isValid ? (
							<>
								{!isViewing && (
									<>
										<CallsCount />
										<Calls />
										<AgeGroupsChart />
										{selectedStory.id === "food_insecurity" && <HoursPerMeal />}
										<NeedsMet />
										<Donate />
									</>
								)}
								<ViewOnMonitors />
							</>
						) : (
							<div className="text-red-400 text-center">
								This zipcode is outside of our service area
							</div>
						))}
				</>
			)}
		</div>
	);
};

export default Public;
