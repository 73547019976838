import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import Card from "../../components/Card";
import { useStates } from "../../hooks/reusable/useStates";

type State = {
	adultCount: number;
	childCount: number;
};
const HoursPerMeal: React.FC = () => {
	const [state, setState] = useStates<State>({
		adultCount: 1,
		childCount: 0,
	});
	return (
		<Card className="hours-per-meal">
			<div className="text-center">In 2021</div>
			<div className="flex justify-center gap-6 mt-4">
				<div className="text-2xl text-center text-uwgc-charcoal">
					<div className="flex gap-3">
						<div className="text-6xl text-uwgc-midblue font-extrabold">{state.adultCount}</div>
						<div className="flex flex-col justify-center items-center">
							<button
								className="up"
								onClick={() => {
									setState({ adultCount: state.adultCount + 1 });
								}}
							>
								<ChevronUpIcon className="h-7 w-7" />
							</button>
							<button
								className="down"
								onClick={() =>
									state.adultCount > 0 && setState({ adultCount: state.adultCount - 1 })
								}
							>
								<ChevronDownIcon className="h-7 w-7" />
							</button>
						</div>
					</div>
					<div className="mt-2">Adult</div>
				</div>
				<div className="text-2xl text-center text-uwgc-charcoal">
					<div className="flex gap-3">
						<div className="text-6xl text-uwgc-midblue font-extrabold">{state.childCount}</div>
						<div className="flex flex-col justify-center items-center ">
							<button
								className="up"
								onClick={() => {
									setState({ childCount: state.childCount + 1 });
								}}
							>
								<ChevronUpIcon className="h-7 w-7" />
							</button>
							<button
								className="down"
								onClick={() =>
									state.childCount > 0 && setState({ childCount: state.childCount - 1 })
								}
							>
								<ChevronDownIcon className="h-7 w-7" />
							</button>
						</div>
					</div>
					<div className="mt-2">Child</div>
				</div>
			</div>
			<div className="text-center gap-6 mt-4 text-lg p-3">
				or{" "}
				<span className="text-uwgc-midblue">
					{state.adultCount * 6.5 + state.childCount * 4.5} hours of work
				</span>{" "}
				on minimum wage to eat per week (21 meals)
			</div>
		</Card>
	);
};

export default HoursPerMeal;
