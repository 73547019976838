import WsError from "../../WsError";
import MapCommandData from "./mapCommandData/MapCommandData";
import StoryEngineState from "./StoryEngineState";

export enum InMainWsDataTypes {
	ERROR, // WsError
	IS_DISPLAY_CONNECTED, // boolean
	STORY_ENGINE_STATE, // StoryEngineState
	MAP_COMMAND, // MapCommandData
	HAS_CONTROL, // boolean
}

interface InMainWsData {
	type: InMainWsDataTypes;
	data: WsError | boolean | StoryEngineState | MapCommandData;
}

export default InMainWsData;
