import ControlStoryCommandData from "../../../types/mainWs/outMainWsData/ControlStoryCommandData";
import {
	createOutMainWsData,
	OutMainWsDataTypes,
} from "../../../types/mainWs/outMainWsData/OutMainWsData";
import useSendOutMainWsData from "./useSendOutMainWsData";

const useSendControlStoryCommandData = () => {
	const sendOutMainWsData = useSendOutMainWsData();
	return (command: ControlStoryCommandData) => {
		sendOutMainWsData(createOutMainWsData(OutMainWsDataTypes.STORY_COMMAND, command));
	};
};

export default useSendControlStoryCommandData;
