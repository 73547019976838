import { useRecoilValue } from "recoil";
import useSendOutPublicControlWsData from "../../../state/publicControlWs/sendOutData/useSendOutPublicControlData";
import {
	createOutPublicControlWsData,
	OutPublicControlWsDataTypes,
} from "../../../types/publicControlWs/outPublicControlWsData/OutPublicControlWsData";
import { remainingTimeAtom } from "../publicControl.recoil";
import { intervalToDuration } from "date-fns";
import { useEffect, useState } from "react";
import "./view-on-monitors.scss";
import useSendPublicControlViewerCommand from "../../../state/publicControlWs/sendOutData/useSendPublicControlViewerCommand";
import {
	createPublicControlViewerCommand,
	PublicControlViewerCommandTypes,
} from "../../../types/publicControlWs/outPublicControlWsData/PublicControlViewerCommand";
import PublicControlViewerDetailTypes from "../../../types/PublicControlViewerDetailTypes";
import { useGetStoryCallZipCodesLazyQuery } from "../../../generated/graphql";
import { selectedStoryAtom } from "../public.recoil";

const EXTEND_TIME_LENGTH = 30000;
const MAX_TIME_LENGTH = 120000;

const Viewer: React.FC = () => {
	const selectedStory = useRecoilValue(selectedStoryAtom)!;
	const [zipCodeInputValue, setZipCodeInputValue] = useState("");
	const [detailType, setDetailType] = useState(PublicControlViewerDetailTypes.CALLS);
	const [shouldShowInvalidZipCodeMsg, setShouldShowInvalidZipCodeMsg] = useState(false);

	const remainingTime = useRecoilValue(remainingTimeAtom);
	const sendOutPublicControlWsData = useSendOutPublicControlWsData();
	const sendPublicControlViewerCommand = useSendPublicControlViewerCommand();

	const [getStoryCallZipCodesForValidation, getStoryCallZipCodesForValidationRes] =
		useGetStoryCallZipCodesLazyQuery({
			fetchPolicy: "cache-and-network",
		});

	const reset = () => {
		setZipCodeInputValue("");
		setDetailType(PublicControlViewerDetailTypes.CALLS);
	};

	const setZipCodeValidated = () => {
		void getStoryCallZipCodesForValidation({
			variables: {
				input: {
					filter: {
						storyId: selectedStory.id,
						likeZipCode: `%${zipCodeInputValue}%`,
					},
				},
			},
		});
	};

	useEffect(() => {
		return () => {
			reset();
		};
	}, []);

	useEffect(() => {
		const { called, loading, data } = getStoryCallZipCodesForValidationRes;
		if (called && !loading && data) {
			const isValidZipCode = data.getStoryCallZipCodes.count !== 0;
			if (isValidZipCode) {
				sendPublicControlViewerCommand(
					createPublicControlViewerCommand(
						PublicControlViewerCommandTypes.SET_ZIP_CODE,
						zipCodeInputValue,
					),
				);
				setShouldShowInvalidZipCodeMsg(false);
			} else {
				setShouldShowInvalidZipCodeMsg(true);
			}
		}
	}, [getStoryCallZipCodesForValidationRes.loading]);

	const canExtendTime = remainingTime + EXTEND_TIME_LENGTH <= MAX_TIME_LENGTH;
	const remainingTimeDuration = intervalToDuration({ start: 0, end: remainingTime });
	const remainingTimeFormatted = `${remainingTimeDuration.minutes
		?.toString()
		.padStart(2, "0")}:${remainingTimeDuration.seconds?.toString().padStart(2, "0")}`;

	return (
		<div className="viewer">
			<div>Enter zip code to update map</div>
			<div className="flex gap-4">
				<input
					className="rounded h-14 w-2/3"
					maxLength={5}
					value={zipCodeInputValue}
					placeholder="Zip code"
					onChange={(e) => setZipCodeInputValue(e.target.value)}
				/>
				<button
					className="rounded bg-uwgc-midblue text-white p-3"
					disabled={zipCodeInputValue.length !== 5}
					onClick={() => setZipCodeValidated()}
				>
					UPDATE
				</button>
			</div>
			{shouldShowInvalidZipCodeMsg && (
				<div className="text-red-400">This zipcode is outside of our service area</div>
			)}

			<button
				className="w-full bg-uwgc-midblue text-white p-3 mt-3 rounded"
				onClick={() =>
					sendPublicControlViewerCommand(
						createPublicControlViewerCommand(
							PublicControlViewerCommandTypes.VIEW_ALL_CLEVELAND,
							undefined,
						),
					)
				}
			>
				VIEW ALL CLEVELAND
			</button>
			<div className="p-3 w-full border-b border-solid border-white my-3"></div>
			<div className="text-2xl font-extrabold">View details:</div>
			<div className="flex gap-3 items-center">
				<input
					id="gender"
					type="radio"
					checked={detailType === PublicControlViewerDetailTypes.CALLS}
					onChange={(e) => {
						if (e.target.checked) {
							setDetailType(PublicControlViewerDetailTypes.CALLS);
							sendPublicControlViewerCommand(
								createPublicControlViewerCommand(
									PublicControlViewerCommandTypes.VIEW_ALL_CALLS,
									undefined,
								),
							);
						}
					}}
				/>
				<div>All calls</div>
			</div>
			<div className="flex gap-3 items-center">
				<input
					id="gender"
					type="radio"
					checked={detailType === PublicControlViewerDetailTypes.GENDER}
					onChange={(e) => {
						if (e.target.checked) {
							setDetailType(PublicControlViewerDetailTypes.GENDER);
							sendPublicControlViewerCommand(
								createPublicControlViewerCommand(
									PublicControlViewerCommandTypes.VIEW_GENDER_OF_CALLERS,
									undefined,
								),
							);
						}
					}}
				/>
				<div>View gender of callers</div>
			</div>
			<div className="flex gap-3 items-center">
				<input
					id="age"
					type="radio"
					checked={detailType === PublicControlViewerDetailTypes.AGE_GROUP}
					onChange={(e) => {
						if (e.target.checked) {
							setDetailType(PublicControlViewerDetailTypes.AGE_GROUP);
							sendPublicControlViewerCommand(
								createPublicControlViewerCommand(
									PublicControlViewerCommandTypes.VIEW_AGE_OF_CALLERS,
									undefined,
								),
							);
						}
					}}
				/>
				<div>View age of callers</div>
			</div>
			<div className="flex gap-3 items-center">
				<input
					id="services"
					type="radio"
					checked={detailType === PublicControlViewerDetailTypes.SERVICE}
					onChange={(e) => {
						if (e.target.checked) {
							setDetailType(PublicControlViewerDetailTypes.SERVICE);
							sendPublicControlViewerCommand(
								createPublicControlViewerCommand(
									PublicControlViewerCommandTypes.VIEW_SERVICES_REQUESTED,
									undefined,
								),
							);
						}
					}}
				/>
				<div>View services requested</div>
			</div>

			<div className="txt-3xl font-semibold text-center mt-6">{remainingTimeFormatted}</div>
			<div className="flex gap-3 w-full">
				<button
					className="button-orange w-1/2"
					onClick={() =>
						sendOutPublicControlWsData(
							createOutPublicControlWsData(OutPublicControlWsDataTypes.VIEW, {
								isView: false,
							}),
						)
					}
				>
					EXIT
				</button>
				<button
					className="button-orange w-1/2"
					disabled={!canExtendTime}
					onClick={() =>
						sendOutPublicControlWsData(
							createOutPublicControlWsData(OutPublicControlWsDataTypes.EXTEND, undefined),
						)
					}
				>
					EXTEND
				</button>
			</div>
		</div>
	);
};

export default Viewer;
