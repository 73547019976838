import DisplayModes from "../../DisplayModes";
import ControlMapCommandData from "./ControlMapCommandData";
import ControlStoryCommandData from "./ControlStoryCommandData";

export enum OutMainWsDataTypes {
	SET_DISPLAY_MODE,
	STORY_COMMAND,
	MAP_COMMAND,
	SET_IS_LOCKED,
	OVERRIDE_CONNECTION,
	RELOAD_DISPLAY,
}

type OutMainWsDataTypeMap = {
	[OutMainWsDataTypes.SET_DISPLAY_MODE]: DisplayModes;
	[OutMainWsDataTypes.STORY_COMMAND]: ControlStoryCommandData;
	[OutMainWsDataTypes.MAP_COMMAND]: ControlMapCommandData;
	[OutMainWsDataTypes.SET_IS_LOCKED]: boolean;
	[OutMainWsDataTypes.OVERRIDE_CONNECTION]: undefined;
	[OutMainWsDataTypes.RELOAD_DISPLAY]: undefined;
};

interface OutMainWsData<T extends OutMainWsDataTypes = OutMainWsDataTypes> {
	type: T;
	data: OutMainWsDataTypeMap[T];
}

export const createOutMainWsData = <T extends OutMainWsDataTypes>(
	type: T,
	data: OutMainWsDataTypeMap[T],
): OutMainWsData<T> => {
	return {
		type,
		data,
	};
};

export default OutMainWsData;
