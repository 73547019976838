import { FormEvent } from "react";
import { LoginInput } from "../../generated/graphql";
import { useStates } from "../../hooks/reusable/useStates";
import useLogin from "../../state/user/useLogin";
import "./login.scss";

type LoginInputData = Partial<LoginInput>;

const Login: React.FC = () => {
	const [loginInput, setLoginInput] = useStates<LoginInputData>({});

	const { login, loginQueryState } = useLogin();

	const submit = (e: FormEvent) => {
		e.preventDefault();

		login({
			username: loginInput.username!,
			password: loginInput.password!,
			isClear: true,
		});
	};

	return (
		<div className="login">
			<form onSubmit={(e) => submit(e)}>
				<div>
					<div>
						<input
							id="email-address"
							name="email"
							type="text"
							autoComplete="email"
							required
							placeholder="Username"
							disabled={loginQueryState.isLoading}
							value={loginInput.username ?? ""}
							onChange={(e) =>
								setLoginInput({
									username: e.target.value,
								})
							}
						/>
					</div>
					<div>
						<input
							id="password"
							name="password"
							type="password"
							autoComplete="current-password"
							required
							placeholder="Password"
							disabled={loginQueryState.isLoading}
							value={loginInput.password ?? ""}
							onChange={(e) =>
								setLoginInput({
									password: e.target.value,
								})
							}
						/>
					</div>
				</div>

				<div>
					<button type="submit" disabled={loginQueryState.isLoading}>
						Login
					</button>
					{loginQueryState.error !== undefined ? "Wrong username or password" : ""}
				</div>
			</form>
		</div>
	);
};

export default Login;
