import {
	DesktopComputerIcon,
	DeviceMobileIcon,
	RefreshIcon,
	StatusOfflineIcon,
	StatusOnlineIcon,
} from "@heroicons/react/solid";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import {
	hasControlAtom,
	isDisplayConnectedAtom,
	mainWsConnectionStatusAtom,
} from "../state/mainWs/mainWs.recoil";
import useSendOutMainWsData from "../state/mainWs/sendOutData/useSendOutMainWsData";
import "../styles/components/display-status.scss";
import {
	createOutMainWsData,
	OutMainWsDataTypes,
} from "../types/mainWs/outMainWsData/OutMainWsData";
import WsConnectionStatus from "../types/WsConnectionStatus";

const DisplayStatus = () => {
	const [isDisplayReloading, setIsDisplayReloading] = useState(false);

	const mainWsConnectionStatus = useRecoilValue(mainWsConnectionStatusAtom);
	const hasControl = useRecoilValue(hasControlAtom);
	const isDisplayConnected = useRecoilValue(isDisplayConnectedAtom);
	const sendOutMainWsData = useSendOutMainWsData();

	const reloadDisplay = () => {
		sendOutMainWsData(createOutMainWsData(OutMainWsDataTypes.RELOAD_DISPLAY, undefined));

		// fake loading
		setIsDisplayReloading(true);
		setTimeout(() => {
			setIsDisplayReloading(false);
		}, 1000);
	};

	return (
		<div className="display-status">
			{mainWsConnectionStatus === WsConnectionStatus.NOT_CONNECTED ? (
				<StatusOfflineIcon className="h-5 w-5 text-uwgc-midGrey" />
			) : mainWsConnectionStatus === WsConnectionStatus.CONNECTING ? (
				<StatusOnlineIcon className="h-5 w-5 text-uwgc-orange" />
			) : (
				<StatusOnlineIcon className="h-5 w-5 text-uwgc-green" />
			)}
			{hasControl ? (
				<DeviceMobileIcon className="h-5 w-5 text-uwgc-green" />
			) : (
				<DeviceMobileIcon
					className="h-5 w-5 text-uwgc-midGrey cursor-pointer"
					onClick={() =>
						sendOutMainWsData(
							createOutMainWsData(OutMainWsDataTypes.OVERRIDE_CONNECTION, undefined),
						)
					}
				/>
			)}
			{isDisplayConnected ? (
				isDisplayReloading ? (
					<RefreshIcon className="h-5 w-5 text-uwgc-yellow" />
				) : (
					<DesktopComputerIcon
						className={"h-5 w-5 text-uwgc-green " + (hasControl ? "cursor-pointer " : "")}
						onClick={() => {
							if (hasControl) {
								reloadDisplay();
							}
						}}
					/>
				)
			) : (
				<DesktopComputerIcon className="h-5 w-5 text-uwgc-midGrey" />
			)}
		</div>
	);
};

export default DisplayStatus;
