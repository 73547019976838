import React from "react";

const MobileHeader = () => {
	return (
		<div className=" max-h-14 bg-white ">
			<img className=" h-14 " src="/assets/svg/UWGC_Logo.svg" />
		</div>
	);
};

export default MobileHeader;
