import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import useSendControlMapCommandData from "../../../../../state/mainWs/sendOutData/useSendControlMapCommandData";
import {
	ControlMapCommandDataTypes,
	createControlMapCommandData,
} from "../../../../../types/mainWs/outMainWsData/ControlMapCommandData";
import MapDataLayerTypes from "../../../../../types/MapDataLayerTypes";
import { mapSelectedAtom } from "../maps.recoil";
import Map from "./map/Map";

const MapSettings: React.FC = () => {
	const [mapDataLayerType, setMapDataLayerType] = useState(MapDataLayerTypes.SERVICE_HEAT_MAP);
	const [zipCodeInputValue, setZipCodeInputValue] = useState("");

	const [mapSelected, setMapSelected] = useRecoilState(mapSelectedAtom);
	const sendControlMapCommandData = useSendControlMapCommandData();

	useEffect(() => {
		sendControlMapCommandData(
			createControlMapCommandData(ControlMapCommandDataTypes.OPEN_MAP, {
				storyId: mapSelected!.storyId,
			}),
		);

		return () => {
			sendControlMapCommandData(
				createControlMapCommandData(ControlMapCommandDataTypes.CLOSE_MAP, undefined),
			);
		};
	}, []);

	return (
		<div className="map-settings text-uwgc-charcoal">
			<div className="flex justify-between w-full items-center mt-2 mr-3">
				<div
					className="flex gap-2 items-center w-1/3 self-start p-4 cursor-pointer  text-uwgc-midblue hover:text-uwgc-blue"
					onClick={() => setMapSelected(undefined)}
				>
					<ArrowLeftIcon className="h-5 w-5" />
					Back
				</div>
				<div className="font-bold text-3xl w-1/3 text-center ">{mapSelected!.name}</div>
				<div className="flex gap-2 items-center w-1/3 justify-end">
					<button
						className="bg-uwgc-midblue text-white p-3 rounded w-max"
						onClick={() =>
							sendControlMapCommandData(
								createControlMapCommandData(
									ControlMapCommandDataTypes.VIEW_ALL_CLEVELAND,
									undefined,
								),
							)
						}
					>
						VIEW ALL CLEVELAND
					</button>
					<input
						className="max-w-xs border border-gray-400 rounded h-12 w-56"
						placeholder="Enter Zip Code"
						value={zipCodeInputValue}
						onChange={(e) => setZipCodeInputValue(e.target.value)}
					/>
					<button
						className="bg-uwgc-midblue text-white p-3 rounded w-max"
						onClick={() =>
							sendControlMapCommandData(
								createControlMapCommandData(
									ControlMapCommandDataTypes.SET_ZIP_CODE,
									zipCodeInputValue,
								),
							)
						}
					>
						UPDATE
					</button>
				</div>
			</div>

			<Map />

			<div className="text-2xl font-extrabold">View details:</div>
			<div className=" grid grid-cols-3 w-full px-6 gap-3">
				<div className="flex gap-3 items-center">
					<input
						className="square-radio"
						id="all"
						type="radio"
						checked={mapDataLayerType === MapDataLayerTypes.SERVICE_HEAT_MAP}
						onChange={(e) => {
							if (e.target.checked) {
								setMapDataLayerType(MapDataLayerTypes.SERVICE_HEAT_MAP);
								sendControlMapCommandData(
									createControlMapCommandData(
										ControlMapCommandDataTypes.SET_DATA_LAYER,
										MapDataLayerTypes.SERVICE_HEAT_MAP,
									),
								);
							}
						}}
					/>
					<label htmlFor="all">All calls</label>
				</div>
				<div className="flex gap-3 items-center">
					<input
						className="square-radio"
						id="gender"
						type="radio"
						checked={mapDataLayerType === MapDataLayerTypes.GENDER_SCATTER_PLOT}
						onChange={(e) => {
							if (e.target.checked) {
								setMapDataLayerType(MapDataLayerTypes.GENDER_SCATTER_PLOT);
								sendControlMapCommandData(
									createControlMapCommandData(
										ControlMapCommandDataTypes.SET_DATA_LAYER,
										MapDataLayerTypes.GENDER_SCATTER_PLOT,
									),
								);
							}
						}}
					/>
					<label htmlFor="gender">View gender of callers</label>
				</div>
				<div className="flex gap-3 items-center">
					<input
						className="square-radio"
						id="age"
						type="radio"
						checked={mapDataLayerType === MapDataLayerTypes.AGE_GROUP_SCATTER_PLOT}
						onChange={(e) => {
							if (e.target.checked) {
								setMapDataLayerType(MapDataLayerTypes.AGE_GROUP_SCATTER_PLOT);
								sendControlMapCommandData(
									createControlMapCommandData(
										ControlMapCommandDataTypes.SET_DATA_LAYER,
										MapDataLayerTypes.AGE_GROUP_SCATTER_PLOT,
									),
								);
							}
						}}
					/>
					<label htmlFor="age">View age of callers</label>
				</div>
				<div className="flex gap-3 items-center">
					<input
						className="square-radio"
						id="services"
						type="radio"
						checked={mapDataLayerType === MapDataLayerTypes.SERVICE_SCATTER_PLOT}
						onChange={(e) => {
							if (e.target.checked) {
								setMapDataLayerType(MapDataLayerTypes.SERVICE_SCATTER_PLOT);
								sendControlMapCommandData(
									createControlMapCommandData(
										ControlMapCommandDataTypes.SET_DATA_LAYER,
										MapDataLayerTypes.SERVICE_SCATTER_PLOT,
									),
								);
							}
						}}
					/>
					<label htmlFor="services">View services requested</label>
				</div>
			</div>
		</div>
	);
};

export default MapSettings;
