import { useRecoilState, useRecoilValue } from "recoil";
import { selectedStoryAtom } from "../stories.recoil";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import useSendControlStoryCommandData from "../../../../../state/mainWs/sendOutData/useSendControlStoryCommandData";
import {
	ControlStoryCommandDataTypes,
	createControlStoryCommandData,
} from "../../../../../types/mainWs/outMainWsData/ControlStoryCommandData";
import { canControlSelector } from "../../../../../state/mainWs/mainWs.recoil";

const StoryDashboard: React.FC = () => {
	const [selectedStory, setSelectedStory] = useRecoilState(selectedStoryAtom);
	const canControl = useRecoilValue(canControlSelector);

	const sendControlStoryCommand = useSendControlStoryCommandData();

	return (
		<div className="story-dashboard">
			<div
				style={{
					background: 'url("/assets/jpg/' + selectedStory?.id + '_cover.jpg")',
					backgroundSize: "cover",
				}}
				className="flex items-stretch h-28"
			>
				<div className="flex w-full h-full items-center justify-between backdrop-brightness-50 text-white">
					<div
						className="flex gap-2 items-center w-1/12 self-start p-4 cursor-pointer hover:text-uwgc-yellow"
						onClick={() => setSelectedStory(undefined)}
					>
						<ArrowLeftIcon className="h-5 w-5" />
						Back
					</div>
					<div className="text-3xl font-bold">{selectedStory!.name}</div>
					<div className="w-1/12"></div>
				</div>
			</div>
			<div className="story-options">
				<button
					className={!canControl ? "opacity-50" : ""}
					disabled={!canControl}
					onClick={() =>
						sendControlStoryCommand(
							createControlStoryCommandData(
								ControlStoryCommandDataTypes.SET_STORY,
								selectedStory!.id,
							),
						)
					}
				>
					RUN STORY
				</button>
			</div>
		</div>
	);
};

export default StoryDashboard;
