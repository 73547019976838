import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { hasControlAtom, isDisplayConnectedAtom } from "../mainWs.recoil";
import InMainWsData, { InMainWsDataTypes } from "../../../types/mainWs/inMainWsData/InMainWsData";
import StoryEngineState from "../../../types/mainWs/inMainWsData/StoryEngineState";
import WsError from "../../../types/WsError";
import useHandleStoryEngineState from "./useHandleStoryEngineState";
import useHandleMapCommandData from "./useHandleMapCommandData";
import MapCommandData from "../../../types/mainWs/inMainWsData/mapCommandData/MapCommandData";

const useHandleInMainWsData = () => {
	const setHasControl = useSetRecoilState(hasControlAtom);
	const setIsDisplayConnected = useSetRecoilState(isDisplayConnectedAtom);
	const handleStoryEngineState = useHandleStoryEngineState();
	const handleMapCommandData = useHandleMapCommandData();

	return useCallback(
		(data: InMainWsData) => {
			switch (data.type) {
				case InMainWsDataTypes.ERROR:
					{
						const err = data.data as WsError;
						// @TODO show error on screen
						console.error(err.message);
					}
					break;
				case InMainWsDataTypes.IS_DISPLAY_CONNECTED:
					setIsDisplayConnected(data.data as boolean);
					break;
				case InMainWsDataTypes.STORY_ENGINE_STATE:
					handleStoryEngineState(data.data as StoryEngineState);
					break;
				case InMainWsDataTypes.MAP_COMMAND:
					handleMapCommandData(data.data as MapCommandData);
					break;
				case InMainWsDataTypes.HAS_CONTROL:
					setHasControl(data.data as boolean);
					break;
				default: {
					console.error("Unknown InMainWsDataTypes");
				}
			}
		},
		[handleStoryEngineState, handleMapCommandData],
	);
};

export default useHandleInMainWsData;
