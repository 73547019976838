import { atom } from "recoil";

export const canViewAtom = atom<boolean>({
	key: "canView",
	default: false,
});

export const isViewingAtom = atom<boolean>({
	key: "isViewing",
	default: false,
});

export const remainingTimeAtom = atom<number>({
	key: "remainingTime",
	default: 0,
});
