import WsError from "../../WsError";

export enum InPublicControlWsDataTypes {
	ERROR, // WsError
	CAN_VIEW, // boolean
	IS_VIEWING, // boolean
	REMAINING_TIME, // number
}

interface InPublicControlWsData {
	type: InPublicControlWsDataTypes;
	data: WsError | boolean | number;
}

export default InPublicControlWsData;
