import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import Card from "../../components/Card";
import { selectedStoryAtom } from "./public.recoil";

const Donate: React.FC = () => {
	const selectedStory = useRecoilValue(selectedStoryAtom)!;
	const donationAmmountPerCount =
		selectedStory.id === "food_insecurity" ? 2.36 : selectedStory.id === "mental_health" ? 12 : 25;

	const [donation, setDonation] = useState(donationAmmountPerCount);

	return (
		<Card className="donate text-lg text-uwgc-charcoal text-center">
			<div className="text-2xl text-center text-uwgc-charcoal flex justify-center">
				<div className="flex gap-3 my-4">
					<div className="text-6xl text-uwgc-midblue font-extrabold">
						${donation.toLocaleString(undefined, { minimumFractionDigits: 2 })}
					</div>
					<div className="flex flex-col justify-center items-center">
						<button
							className="up"
							onClick={() => {
								setDonation(Number((donation + donationAmmountPerCount).toFixed(2)));
							}}
						>
							<ChevronUpIcon className="h-7 w-7" />
						</button>
						<button
							className="down"
							onClick={() =>
								donation > donationAmmountPerCount &&
								setDonation(Number((donation - donationAmmountPerCount).toFixed(2)))
							}
						>
							<ChevronDownIcon className="h-7 w-7" />
						</button>
					</div>
				</div>
			</div>
			<div>
				Provides{" "}
				<span className="text-uwgc-orange font-semibold">
					{selectedStory.id === "food_insecurity" && (
						<>{(donation / 2.36).toFixed(0)} nutritious meals</>
					)}
					{selectedStory.id === "mental_health" && (
						<>{((donation / 12) * 30).toFixed(0)} mins of counseling</>
					)}
					{selectedStory.id === "housing" && <>{(donation / 25).toFixed(0)} night of shelter</>}
				</span>{" "}
				{selectedStory.id === "food_insecurity" && (
					<>for local children and adults who would otherwise go without</>
				)}
				{selectedStory.id === "mental_health" && (
					<>for local children and adults who are struggling with mental health</>
				)}
				{selectedStory.id === "housing" && (
					<>for those struggling with homelessness in Cleveleand.</>
				)}
			</div>
			<button
				className="w-full h-14 text-center align-middle bg-uwgc-orange text-white rounded font-thin mt-3"
				onClick={() => {
					window.open(
						"https://give.unitedwaycleveland.org/engage/DonationForm.seam?teamName=&amount=" +
							donation.toLocaleString(undefined, { minimumFractionDigits: 2 }) +
							"&teamCampaignId=3996&emailID=&emailid=&contactContactId=&campaignName=United+Way+Donate+Now+CLEVELAND&pageName=UWGC&cid=620687&_ga=2.134119435.484489213.1636643917-2116951871.1633354438",
						"_blank",
					);
				}}
			>
				DONATE{" "}
				<span className="font-semibold">
					${donation.toLocaleString(undefined, { minimumFractionDigits: 2 })}
				</span>{" "}
				NOW
			</button>
		</Card>
	);
};

export default Donate;
