import { useRecoilValue } from "recoil";
import OutMainWsData from "../../../types/mainWs/outMainWsData/OutMainWsData";
import { mainWsAtom } from "../mainWs.recoil";

const useSendOutMainWsData = () => {
	const mainWs = useRecoilValue(mainWsAtom);
	return (data: OutMainWsData) => {
		if (!mainWs) {
			console.error("mainWs not initialized");
			return;
		}

		mainWs.send(data);
	};
};

export default useSendOutMainWsData;
