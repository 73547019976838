import { atom, selector } from "recoil";
import WsConnectionStatus from "../../types/WsConnectionStatus";
import InMainWsData from "../../types/mainWs/inMainWsData/InMainWsData";
import WebSocketManager from "../../classes/WebSocketManager";
import OutMainWsData from "../../types/mainWs/outMainWsData/OutMainWsData";

export const mainWsAtom = atom<WebSocketManager<InMainWsData, OutMainWsData> | undefined>({
	key: "mainWs",
	default: undefined,
	dangerouslyAllowMutability: true,
});

export const mainWsConnectionStatusAtom = atom<WsConnectionStatus>({
	key: "mainWsConnectionStatus",
	default: WsConnectionStatus.NOT_CONNECTED,
});

export const hasControlAtom = atom<boolean>({
	key: "hasControl",
	default: false,
});

export const isDisplayConnectedAtom = atom<boolean>({
	key: "isDisplayConnected",
	default: false,
});

export const canControlSelector = selector<boolean>({
	key: "canControl",
	get: ({ get }) => {
		const mainWsConnectionStatus = get(mainWsConnectionStatusAtom);
		const hasControl = get(hasControlAtom);
		const isDisplayConnected = get(isDisplayConnectedAtom);
		return (
			mainWsConnectionStatus === WsConnectionStatus.CONNECTED && hasControl && isDisplayConnected
		);
	},
});
