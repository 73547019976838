import { useCallback } from "react";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useGetCurrentUserLazyQuery } from "../../generated/graphql";
import QueryState from "../../types/QueryState";
import { handleApolloError } from "../../utils/ApolloUtils";
import { currentUserAtom } from "./user.recoil";

const useGetCurrentUser = () => {
	const [getCurrentUserQueryState, setGetCurrentUserQueryState] = useState<QueryState>({
		wasCalled: false,
		isLoading: false,
		error: undefined,
	});
	const [currentUser, setCurrentUser] = useRecoilState(currentUserAtom);

	const [_getCurrentUser, getCurrentUserRes] = useGetCurrentUserLazyQuery({
		fetchPolicy: "cache-and-network",
	});

	const getCurrentUser = useCallback(() => {
		if (!currentUser) {
			setGetCurrentUserQueryState({
				wasCalled: true,
				isLoading: true,
				error: undefined,
			});
			void _getCurrentUser();
		}
	}, [currentUser]);

	useEffect(() => {
		if (getCurrentUserRes.called && !getCurrentUserRes.loading) {
			if (getCurrentUserRes.data) {
				setCurrentUser(getCurrentUserRes.data.getCurrentUser);
			} else if (getCurrentUserRes.error) {
				setGetCurrentUserQueryState((state) => {
					return {
						...state,
						isLoading: false,
						error: getCurrentUserRes.error
							? handleApolloError(getCurrentUserRes.error)
							: "Unknown error",
					};
				});
			}
		}
	}, [getCurrentUserRes.loading]);

	useEffect(() => {
		if (currentUser) {
			setGetCurrentUserQueryState((state) => {
				return {
					...state,
					isLoading: false,
				};
			});
		}
	}, [currentUser]);

	return {
		getCurrentUser,
		getCurrentUserQueryState,
	};
};

export default useGetCurrentUser;
