import { useEffect } from "react";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import WebSocketManager from "../../classes/WebSocketManager";
import { publicControlWsAtom, publicControlWsConnectionStatusAtom } from "./publicControlWs.recoil";
import InPublicControlWsData from "../../types/publicControlWs/inPublicControlWsData/InPublicControlWsData";
import OutPublicControlWsData from "../../types/publicControlWs/outPublicControlWsData/OutPublicControlWsData";
import WsConnectionStatus from "../../types/WsConnectionStatus";
import useHandleInPublicControlWsData from "./handleInData/useHandleInPublicControlWsData";
import useSubscriptionsHandler from "../../hooks/useSubscriptionsHandler";
import env from "../../env";

const usePublicControlWs = () => {
	const [publicControlWs, setPublicControlWs] = useRecoilState(publicControlWsAtom);
	const resetPublicControlWs = useResetRecoilState(publicControlWsAtom);
	const setPublicControlWsConnectionStatus = useSetRecoilState(publicControlWsConnectionStatusAtom);
	const resetPublicControlWsConnectionStatus = useResetRecoilState(
		publicControlWsConnectionStatusAtom,
	);
	const handleInPublicControlWsData = useHandleInPublicControlWsData();

	const { addSubscription, unsubscribeAll } = useSubscriptionsHandler();

	const onConnectionChanged = (connection: WsConnectionStatus) => {
		setPublicControlWsConnectionStatus(connection);
	};

	useEffect(() => {
		const newPublicControlWs = new WebSocketManager<InPublicControlWsData, OutPublicControlWsData>(
			env.REACT_APP_API_WS_BASE_URL + "/ws",
			"PUBLIC_CONTROL",
		);
		addSubscription(newPublicControlWs.connectionStatusObservable.subscribe(onConnectionChanged));
		addSubscription(
			newPublicControlWs.onDataReceivedObservable.subscribe(handleInPublicControlWsData),
		);
		newPublicControlWs.open();
		setPublicControlWs(newPublicControlWs);

		return () => {
			resetPublicControlWsConnectionStatus();
			unsubscribeAll();
			newPublicControlWs.close();

			resetPublicControlWs();
		};
	}, []);

	useEffect(() => {
		if (publicControlWs) {
			unsubscribeAll();
			addSubscription(publicControlWs.connectionStatusObservable.subscribe(onConnectionChanged));
			addSubscription(
				publicControlWs.onDataReceivedObservable.subscribe(handleInPublicControlWsData),
			);
		}
	}, [handleInPublicControlWsData]);
};

export default usePublicControlWs;
