import { useRef } from "react";
import { Subscription } from "rxjs";

const useSubscriptionsHandler = () => {
	const subscriptionsRef = useRef<Subscription[]>([]);

	return {
		addSubscription: (subscription: Subscription) => {
			subscriptionsRef.current.push(subscription);
		},
		unsubscribeAll: () => {
			for (const subscription of subscriptionsRef.current) {
				subscription.unsubscribe();
			}
			subscriptionsRef.current = [];
		},
	};
};

export default useSubscriptionsHandler;
