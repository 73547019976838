import { useState } from "react";

interface Props {
	storyId: string;
}
const StoryImage: React.FC<Props> = ({ storyId }) => {
	const [hasImage, setHasImage] = useState(true);

	return (
		<>
			{hasImage ? (
				<img
					src={"/assets/jpg/" + storyId + "_cover.jpg"}
					alt="food"
					onError={(err) => {
						setHasImage(false);
					}}
				/>
			) : (
				<div className="bg-uwgc-charcoal w-full h-full absolute -z-50 rounded-md"></div>
			)}
		</>
	);
};

export default StoryImage;
