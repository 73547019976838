import { atom } from "recoil";
import { SectionData, StoryData } from "../../../../../types/StoryData";

export const storyEngineStateAtom = atom<{
	story: StoryData | undefined;
	section: SectionData | undefined;
	time: number;
	isPaused: boolean;
}>({
	key: "storyEngineState",
	default: {
		story: undefined,
		section: undefined,
		time: 0,
		isPaused: false,
	},
});
