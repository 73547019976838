import { useEffect } from "react";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import WebSocketManager from "../../classes/WebSocketManager";
import { LocalStorageKeys } from "../../constants/LocalStorageKeys";
import {
	hasControlAtom,
	isDisplayConnectedAtom,
	mainWsAtom,
	mainWsConnectionStatusAtom,
} from "./mainWs.recoil";
import InMainWsData from "../../types/mainWs/inMainWsData/InMainWsData";
import OutMainWsData from "../../types/mainWs/outMainWsData/OutMainWsData";
import WsConnectionStatus from "../../types/WsConnectionStatus";
import useHandleInMainWsData from "./handleInData/useHandleInMainWsData";
import useSubscriptionsHandler from "../../hooks/useSubscriptionsHandler";
import env from "../../env";

const useMainWs = () => {
	const [mainWs, setMainWs] = useRecoilState(mainWsAtom);
	const resetMainWs = useResetRecoilState(mainWsAtom);
	const setMainWsConnectionStatus = useSetRecoilState(mainWsConnectionStatusAtom);
	const setHasControl = useSetRecoilState(hasControlAtom);
	const setIsDisplayConnected = useSetRecoilState(isDisplayConnectedAtom);
	const resetMainWsConnectionStatus = useResetRecoilState(mainWsConnectionStatusAtom);
	const handleInMainWsData = useHandleInMainWsData();

	const { addSubscription, unsubscribeAll } = useSubscriptionsHandler();

	const onConnectionChanged = (connection: WsConnectionStatus) => {
		setMainWsConnectionStatus(connection);

		if (connection !== WsConnectionStatus.CONNECTED) {
			// reset status
			setHasControl(false);
			setIsDisplayConnected(false);
		}
	};

	useEffect(() => {
		const token = localStorage.getItem(LocalStorageKeys.TOKEN);
		const newMainWs = new WebSocketManager<InMainWsData, OutMainWsData>(
			env.REACT_APP_API_WS_BASE_URL + "/" + token,
			"CONTROL",
		);
		addSubscription(newMainWs.connectionStatusObservable.subscribe(onConnectionChanged));
		addSubscription(newMainWs.onDataReceivedObservable.subscribe(handleInMainWsData));
		newMainWs.open();
		setMainWs(newMainWs);

		return () => {
			resetMainWsConnectionStatus();
			unsubscribeAll();
			newMainWs.close();

			resetMainWs();
		};
	}, []);

	useEffect(() => {
		if (mainWs) {
			unsubscribeAll();
			addSubscription(mainWs.connectionStatusObservable.subscribe(onConnectionChanged));
			addSubscription(mainWs.onDataReceivedObservable.subscribe(handleInMainWsData));
		}
	}, [handleInMainWsData]);
};

export default useMainWs;
