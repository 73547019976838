export enum EnvTypes {
	Production = "production",
	Staging = "staging",
	Development = "development",
}

export interface Env {
	NODE_ENV: EnvTypes;
	REACT_APP_API_BASE_URL: string;
	REACT_APP_API_WS_BASE_URL: string;
}

let env: Env;

const loadEnv = <TEnvKey extends keyof Env, TDefaultValue extends Env[TEnvKey]>(
	envKey: TEnvKey,
	options: { isOptional?: boolean; isNumber?: boolean; defaultValue?: TDefaultValue } = {
		isOptional: false,
		isNumber: false,
	},
) => {
	const envVal = process.env[envKey];

	if (envVal === undefined) {
		if (options.defaultValue !== undefined) {
			env![envKey] = options.defaultValue as never;
			return;
		} else if (!options.isOptional) {
			throw Error(`env ${envKey} is required`);
		} else {
			return;
		}
	}

	if (options.isNumber) {
		const numEnvVal = parseInt(envVal as string);
		if (isNaN(numEnvVal)) {
			throw Error(`env ${envKey} must be a number`);
		}
		env![envKey] = numEnvVal as never;
	} else {
		env![envKey] = envVal as never;
	}
};

const init = () => {
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (env !== undefined) return;
	env = {} as any;

	if (process.env.REACT_APP_NODE_ENV_OVERRIDE !== undefined) {
		switch (process.env.REACT_APP_NODE_ENV_OVERRIDE as any) {
			case EnvTypes.Production:
				env.NODE_ENV = EnvTypes.Production;
				break;
			case EnvTypes.Staging:
				env.NODE_ENV = EnvTypes.Staging;
				break;
			case EnvTypes.Development:
				env.NODE_ENV = EnvTypes.Development;
				break;
			default: {
				console.error(`Invalid REACT_APP_NODE_ENV_OVERRIDE value, ${process.env.NODE_ENV}`);
			}
		}
	} else {
		switch (process.env.NODE_ENV as any) {
			case EnvTypes.Production:
				env.NODE_ENV = EnvTypes.Production;
				break;
			case EnvTypes.Staging:
				env.NODE_ENV = EnvTypes.Staging;
				break;
			case EnvTypes.Development:
				env.NODE_ENV = EnvTypes.Development;
				break;
			default: {
				console.error(`Invalid NODE_ENV value, ${process.env.NODE_ENV}`);
			}
		}
	}

	loadEnv("REACT_APP_API_BASE_URL");
	loadEnv("REACT_APP_API_WS_BASE_URL");
};

init();

export default env!;
