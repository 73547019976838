import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import Loading from "../../components/Loading";
import { useGetStoryCallZipCodesLazyQuery } from "../../generated/graphql";
import { selectedStoryAtom, selectedZipCodeAtom, zipCodeInputValueAtom } from "./public.recoil";

const ZIP_CODE_LENGTH = 5;

const EnterZipCode: React.FC = () => {
	const selectedStory = useRecoilValue(selectedStoryAtom)!;
	const [zipCodeInputValue, setZipCodeInputValue] = useRecoilState(zipCodeInputValueAtom);
	const setSelectedZipCode = useSetRecoilState(selectedZipCodeAtom);

	const [getStoryCallZipCodesForValidation, getStoryCallZipCodesForValidationRes] =
		useGetStoryCallZipCodesLazyQuery({
			fetchPolicy: "cache-and-network",
		});

	useEffect(() => {
		setZipCodeInputValue(undefined);
		setSelectedZipCode(undefined);
	}, [selectedStory]);

	useEffect(() => {
		if (zipCodeInputValue !== undefined && zipCodeInputValue.length === ZIP_CODE_LENGTH) {
			void getStoryCallZipCodesForValidation({
				variables: {
					input: {
						filter: {
							storyId: selectedStory.id,
							likeZipCode: zipCodeInputValue,
						},
					},
				},
			});
		} else {
			setSelectedZipCode(undefined);
		}
	}, [zipCodeInputValue]);

	useEffect(() => {
		const { called, loading, data } = getStoryCallZipCodesForValidationRes;
		if (called && !loading && data) {
			if (data.getStoryCallZipCodes.count !== 0) {
				setSelectedZipCode({
					zipCode: zipCodeInputValue!,
					isValid: true,
				});
			} else {
				setSelectedZipCode({
					zipCode: zipCodeInputValue!,
					isValid: false,
				});
			}
		}
	}, [
		getStoryCallZipCodesForValidationRes.called,
		getStoryCallZipCodesForValidationRes.loading,
		getStoryCallZipCodesForValidationRes.data,
	]);

	return (
		<div className="enter-zip-code">
			<div className="info px-2">
				<h3 className="text-uwgc-midblue text-xl font-semibold mb-2">
					{selectedStory.id === "food_insecurity" && "What is food insecurity?"}
					{selectedStory.id === "mental_health" && "What is mental health?"}
					{selectedStory.id === "housing" && "What is housing stability?"}
				</h3>
				<div className="text-uwgc-charcoal mb-6">
					{selectedStory.id === "food_insecurity" && (
						<>
							The fact or an instance of being unable to{" "}
							<span className="text-uwgc-orange font-semibold">consistently access</span> or{" "}
							<span className="text-uwgc-orange font-semibold">afford adequate</span> food.
						</>
					)}
					{selectedStory.id === "mental_health" && (
						<>
							Mental health is the foundation for the
							<span className="text-uwgc-orange font-semibold"> well-being </span> and
							<span className="text-uwgc-orange font-semibold"> effective functioning </span> of
							individuals. It is more than the absence of a mental disorder; it is the ability to
							think, learn, and understand one's emotions and the reactions of others.
						</>
					)}
					{selectedStory.id === "housing" && <></>}
				</div>
			</div>
			<div className="zip-code-input flex flex-col px-2">
				<label className="text-uwgc-charcoal mb-2 font-semibold">
					Enter your zip code and find out.
				</label>
				<div className="w-full max-w-lg">
					<input
						className="w-full max-w-lg border-gray-300 h-14 "
						placeholder="Enter Zip Code"
						maxLength={ZIP_CODE_LENGTH}
						disabled={getStoryCallZipCodesForValidationRes.loading}
						value={zipCodeInputValue ?? ""}
						onChange={(e) => setZipCodeInputValue(e.target.value)}
					/>
					{getStoryCallZipCodesForValidationRes.loading && <Loading loadingType="lds-ring" />}
				</div>
			</div>
		</div>
	);
};

export default EnterZipCode;
