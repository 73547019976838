import env from "./env";
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { LocalStorageKeys } from "./constants/LocalStorageKeys";
import { withScalars } from "apollo-link-scalars";
import { buildClientSchema, IntrospectionQuery } from "graphql";
import introspectionSchema from "./generated/schema.json";

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem(LocalStorageKeys.TOKEN);
	return {
		headers: {
			...headers,
			Authorization: token !== null ? `Bearer ${token}` : "",
		},
	};
});

const typesMap = {
	Date: {
		serialize: (parsed: Date) => {
			return parsed.toJSON();
		},
		parseValue: (raw: string): Date => {
			return new Date(raw);
		},
	},
	NullableDate: {
		serialize: (parsed: Date | null) => {
			if (parsed === null) {
				return null;
			}

			return parsed.toJSON();
		},
		parseValue: (raw: string | null): Date | null => {
			if (raw === null) {
				return null;
			}

			return new Date(raw);
		},
	},
};

const schema = buildClientSchema(introspectionSchema as unknown as IntrospectionQuery);
const scalarTypeDefLink = withScalars({ schema, typesMap });

const httpLink = createHttpLink({
	uri: env.REACT_APP_API_BASE_URL + "/api/graphql",
});

export const apolloClient = new ApolloClient({
	// link order matters
	link: ApolloLink.from([authLink, scalarTypeDefLink, httpLink]),
	cache: new InMemoryCache(),
});
