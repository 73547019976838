import { useRecoilState } from "recoil";
import { storiesAtom } from "../../state/stories.recoil";
import { useEffect, useMemo } from "react";
import { selectedStoryAtom } from "./public.recoil";
import { useGetStoriesJsonLazyQuery } from "../../generated/graphql";
import { StoryData } from "../../types/StoryData";

const SelectIssue: React.FC = () => {
	const [stories, setStories] = useRecoilState(storiesAtom);
	const [selectedStory, setSelectedStory] = useRecoilState(selectedStoryAtom);

	const [getStories, getStoriesRes] = useGetStoriesJsonLazyQuery();

	const setSelectedStoryById = (id: string) => {
		const story = stories.find((story) => story.id === id);
		setSelectedStory(story);
	};

	useEffect(() => {
		void getStories();
	}, []);

	const hasFinishedGettingStories = useMemo(() => {
		return getStoriesRes.called && !getStoriesRes.loading;
	}, [getStoriesRes.called, getStoriesRes.loading]);

	useEffect(() => {
		if (hasFinishedGettingStories) {
			if (getStoriesRes.data) {
				setStories(JSON.parse(getStoriesRes.data.getStoriesJSON) as StoryData[]);
			} else {
				setStories([]);
			}
		}
	}, [hasFinishedGettingStories, getStoriesRes.data]);

	return (
		<div className="w-full px-2 my-6">
			<div className="info px-2 mb-6">
				<h1 className="text-uwgc-midblue text-3xl my-3 font-extrabold">Local Impact Board</h1>
				How do these issues affect my community?
			</div>
			<label className="text-uwgc-charcoal mb-2 font-semibold">Select issue</label>
			<select
				className="w-full max-w-xl border-gray-400 h-12 text-gray-400"
				onChange={(e) => setSelectedStoryById(e.target.value)}
				value={selectedStory?.id}
			>
				{[undefined, ...stories]
					.filter((story) => story?.id !== "resource_development")
					.map((story, i) => {
						return (
							<option className="text-uwgc-charcoal" key={i} value={story?.id}>
								{story?.name}
							</option>
						);
					})}
			</select>
		</div>
	);
};

export default SelectIssue;
