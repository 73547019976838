import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { selectedStoryAtom } from "./stories.recoil";
import Story from "./story/Story";
import StoryList from "./StoryList";
import "./stories.scss";
import { useEffect } from "react";
import { canControlSelector } from "../../../../state/mainWs/mainWs.recoil";
import { storyEngineStateAtom } from "./story/storyEngine.recoil";

const Stories: React.FC = () => {
	const [selectedStory, setSelectedStory] = useRecoilState(selectedStoryAtom);
	const resetStoryEngineState = useResetRecoilState(storyEngineStateAtom);
	const canControl = useRecoilValue(canControlSelector);

	useEffect(() => {
		return () => {
			setSelectedStory(undefined);
		};
	}, []);

	useEffect(() => {
		if (!canControl) {
			resetStoryEngineState();
		}
	}, [canControl]);

	return <div className="stories">{selectedStory ? <Story /> : <StoryList />}</div>;
};

export default Stories;
