import { useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import Loading from "../../../../components/Loading";
import { useGetStoriesJsonLazyQuery } from "../../../../generated/graphql";
import { storiesAtom } from "../../../../state/stories.recoil";
import { StoryData } from "../../../../types/StoryData";
import { selectedStoryAtom } from "./stories.recoil";
import { storyEngineStateAtom } from "./story/storyEngine.recoil";
import StoryImage from "./story/StoryImage";

const StoryList: React.FC = () => {
	const [stories, setStories] = useRecoilState(storiesAtom);
	const setSelectedStory = useSetRecoilState(selectedStoryAtom);
	const storyEngineState = useRecoilValue(storyEngineStateAtom);

	const [getStories, getStoriesRes] = useGetStoriesJsonLazyQuery();

	useEffect(() => {
		void getStories();
	}, []);

	const hasFinishedGettingStories = useMemo(() => {
		return getStoriesRes.called && !getStoriesRes.loading;
	}, [getStoriesRes.called, getStoriesRes.loading]);

	useEffect(() => {
		if (hasFinishedGettingStories) {
			if (getStoriesRes.data) {
				setStories(JSON.parse(getStoriesRes.data.getStoriesJSON) as StoryData[]);
			} else {
				setStories([]);
			}
		}
	}, [hasFinishedGettingStories, getStoriesRes.data]);

	return (
		<>
			{getStoriesRes.loading && <Loading loadingType="lds-ring" />}
			<div className="story-list">
				{stories.map((story) => {
					return (
						<button key={story.id} onClick={() => setSelectedStory(story)}>
							<StoryImage storyId={story.id} />

							<div className={storyEngineState.story?.id === story.id ? "text-uwgc-yellow " : ""}>
								{story.name}
							</div>
						</button>
					);
				})}
			</div>
		</>
	);
};

export default StoryList;
