import React from "react";
import "../styles/components/Loading.scss";

interface Props {
	loadingType: "linear" | "lds-ring";
}

const Loading: React.FC<Props> = ({ loadingType }) => {
	return (
		<>
			{loadingType === "linear" && (
				<div className="linear-activity">
					<div className="indeterminate"></div>
				</div>
			)}
			{loadingType === "lds-ring" && (
				<div className="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			)}
		</>
	);
};

export default Loading;
