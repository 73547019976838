import { LatLngTuple } from "leaflet";
import MapDetailData from "../../../../views/control/dashboard/maps/mapSettings/map/types/MapDetailData";
import MapLayerData from "../../../../views/control/dashboard/maps/mapSettings/map/types/MapLayerData";

export enum MapCommandDataTypes {
	FIT_LAT_LNGS, // LatLngTuple[]
	SET_LAYER, // MapLayerData
	SET_DETAIL, // MapDetailData
}

interface MapCommandData {
	type: MapCommandDataTypes;
	data: LatLngTuple[] | MapLayerData | MapDetailData;
}

export default MapCommandData;
