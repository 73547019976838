import { CircleMarker, LayerGroup, Polygon, useMapEvents } from "react-leaflet";
import { MapLayerZipCodeScatterPlotData } from "./types/MapLayerData";
import {
	polygon as createPolygon,
	bbox,
	area as getPolygonArea,
	random as turfRandom,
	booleanPointInPolygon,
	Feature,
	Polygon as TurfPolygonClass,
	BBox,
	Properties,
} from "@turf/turf";
import { LatLngTuple } from "leaflet";
import React, { useState } from "react";
import HighlightLayer from "./HighlightLayer";

type TurfPolygon = Feature<TurfPolygonClass, Properties>;

const randomPolygonLatLng = (bbox: BBox, polygon: TurfPolygon) => {
	let latLng: LatLngTuple;
	do {
		latLng = turfRandom.randomPosition(bbox) as LatLngTuple;
	} while (!booleanPointInPolygon(latLng, polygon));

	return latLng;
};

interface Props {
	data: MapLayerZipCodeScatterPlotData;
	nameColorMap: string[];
}

const ScatterPlotLayer: React.FC<Props> = ({ data, nameColorMap }) => {
	// @TODO possible solution for radius
	const [zoomLevel, setZoomLevel] = useState(12);

	const map = useMapEvents({
		zoomend() {
			setZoomLevel(map.getZoom());
		},
	});

	// @TODO based on zoom
	const circleRadius = zoomLevel >= 11 ? 2 : 1;

	const maxValues: { [key: string]: number } = {};

	data.zipCodePairsArr.forEach((zipCodePairs) => {
		zipCodePairs.pairs.forEach((pair) => {
			if (!maxValues[pair.name] || maxValues[pair.name] < pair.count) {
				maxValues[pair.name] = pair.count;
			}
		});
	});

	return (
		<LayerGroup>
			{data.zipCodePairsArr.map((zipCodePairs, i) => {
				return zipCodePairs.polygons.map((polygon, j) => {
					return (
						<Polygon
							key={i + "-" + j}
							pathOptions={{
								// color: "#252a69",
								fillOpacity: 0.5,
								fillColor: "white",
								stroke: false,
							}}
							positions={polygon}
						/>
					);
				});
			})}

			{data.zipCodePairsArr.map((zipCodePairs, i) => {
				return zipCodePairs.polygons.map((polygon, j) => {
					const polygonObj = createPolygon([polygon]);
					const bboxObj = bbox(polygonObj);
					const area = getPolygonArea(polygonObj);
					const pointCount = Math.trunc(area / 1000000) * 2;

					return (
						<React.Fragment key={i + "-" + j}>
							{zipCodePairs.pairs.map((pair, k) => {
								const scale = pair.count / zipCodePairs.totalCount;
								const scaledPointCount = Math.ceil(pointCount * scale);

								const points: LatLngTuple[] = [];
								for (let l = 0; l < scaledPointCount; ++l) {
									points.push(randomPolygonLatLng(bboxObj, polygonObj));
								}

								const color = nameColorMap[k];

								const opacity =
									pair.count / maxValues[pair.name] < 0.5 ? 0.5 : pair.count / maxValues[pair.name];
								const sizePercent =
									pair.count / maxValues[pair.name] < 0.2 ? 0.2 : pair.count / maxValues[pair.name];

								return (
									<React.Fragment key={i + "-" + j + "-" + k}>
										{points.map((point, m) => {
											// const x = createCircle(point, 0.1, {
											// 	steps: 6,
											// });

											return (
												<CircleMarker
													key={i + "-" + j + "-" + k + "-" + m}
													pathOptions={{
														color,
														stroke: false,
													}}
													radius={8 * sizePercent * circleRadius}
													center={point}
													fillOpacity={opacity}
												/>
											);
										})}
									</React.Fragment>
								);
							})}
							<Polygon
								key={i + "-" + j}
								pathOptions={{
									color: "#4b4f58",
									fill: false,
									weight: 1,
								}}
								positions={polygon}
							/>
						</React.Fragment>
					);
				});
			})}

			<HighlightLayer />
		</LayerGroup>
	);
};

export default ScatterPlotLayer;
