export class PathName {
	public static get NOT_FOUND() {
		return "/404-page-not-found";
	}
	public static get UNKNOWN_ERROR() {
		return "/unknown-error";
	}
	public static get UNAUTHORIZED() {
		return "/unauthorized";
	}
	public static get LOGIN() {
		return "/login";
	}
	public static get DEFAULT() {
		return PathName.PUBLIC;
	}
	public static get PUBLIC() {
		return "/public";
	}
	public static get CONTROL() {
		return "/control";
	}
	public static get CONTROL_DASHBOARD() {
		return this.CONTROL + "/dashboard";
	}
}
