import { atom } from "recoil";
import { StoryData } from "../../types/StoryData";

export const selectedStoryAtom = atom<StoryData | undefined>({
	key: "selectedStory_public",
	default: undefined,
});

export const zipCodeInputValueAtom = atom<string | undefined>({
	key: "zipCodeInputValue",
	default: undefined,
});

export const selectedZipCodeAtom = atom<
	| {
			zipCode: string;
			isValid: boolean;
	  }
	| undefined
>({
	key: "selectedZipCode",
	default: undefined,
});
